<template>
  <v-textarea
    :label="i18n(label)"
    :dense="outlined"
    :outlined="outlined"
    :readonly="readonly"
    :autofocus="autofocus"
    :hide-details="hideDetails"
    v-model="str"
    :rows="rows"
    auto-grow
  />
</template>

<script>

export default {
  props: {
    value: {},
    rows: { default: 1 },
    label: { default: 'field.text' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    let str = String(this.value || '').trim()
    if (!str) str = null
    return { str }
  },

  watch: {
    str () {
      let str = String(this.str || '').trim()
      if (!str) str = null

      let value = String(this.value || '').trim()
      if (!value) value = null

      str !== value && this.$emit('input', str)
    },

    value () {
      let str = String(this.str || '').trim()
      if (!str) str = null

      let value = String(this.value || '').trim()
      if (!value) value = null

      const changed = str !== value
      if (changed) this.str = value
    }
  }
}
</script>
